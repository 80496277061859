.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.container-end {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
}

.contentcard {
    display: flex;
    flex: 1 1 1e-09px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.conteudo {
    display: flex;
    flex: 1 1 1e-09px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    padding: 15px;
    margin: 20px;
    width: calc(100% - 40px);
}

.alinhamento {
    display: flex;
    flex: 0 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1%;
}

.form {
    width: 100%;
    flex: 1 1 1e-09px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.fieldTitle{
    color: #25247B;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 78px;
}

.field20 {
    width: 20%;
}

.field25 {
    width: 24.15%;
}

.field49 {
    width: 49.3%;
}

.field58 {
    width: 57.7%;
}

.field78 {
    width: 78.7%;
}

.field100 {
    width: 100%;
}

@media only screen and (max-width: 960px) {
    .field20 {
        width: 49%;
    }
    .field25 {
        width: 49%;
    }
    .field49 {
        width: 49%;
    }
    .field58 {
        width: 49%;
    }
    .field78 {
        width: 49%;
    }
    .field100 {
        width: 100%;
    }
}
  
@media only screen and (max-width: 600px) {
    .alinhamento{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .field20 {
        width: 100%;
        margin-right: 5px;
    }
    .field25 {
        width: 100%;
        margin-right: 5px;
    }
    .field49 {
        width: 100%;
        margin-right: 5px;
    }
    .field58 {
        width: 100%;
        margin-right: 5px;
    }
    .field78 {
        width: 100%;
        margin-right: 5px;
    }
    .field100 {
        width: 100%;
        margin-right: 5px;
    }
}

p-editor-toolbar{
    display: none !important;
}

.logo{
    position: relative;
    width: 150px;
    height: 50px;
}

.container-bar{
    width: 100%;
    height: 64px;
    min-height: 64px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* border-bottom: 1px solid #25247B; */
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.container-center-center{
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.container-start-center{
    width: auto;
    margin-right: 15px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.divtit{
    width: 25%;
    height: 100%;
    border-right: 1px solid  rgba(0, 0, 0, 0.2);
}

.divtit-tit{
    color:#25247B;
    font-weight:500;
    font-size: 20px;
    padding: 5px;
}

.divtit-tit2{
    color:#25247B;
    font-size: 18px;
    padding: 5px;
    padding-top: 0;
}

.title{
    color:#25247B;
    font-weight:550;
    font-size: 20px;
}


.title2{
    color:#25247B;
    font-weight:500;
    font-size: 20px;
}

.container-center-centertit{
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}